import { useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { FC, useMemo, useRef } from "react";
import {
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Mesh,
  MeshStandardMaterial,
  ShaderMaterial,
} from "three";

useTexture.preload("grass.png");
useTexture.preload("grass_ao.png");
useTexture.preload("perlin.png");

const gx = 0.03;
const gy = 0.03;
const w = 500;
const l = 550;
const h = 0.15;
const d = 2;
const e = 3;
const ses = 0.015;

const r = 1.1;
const g = 1.6;
const b = 1.4;

const vertexShader = `
            // attribute vec2 uv;
            // attribute vec3 position;
            attribute vec2 per;
            uniform sampler2D perlin;
            uniform sampler2D mapMask;
            uniform bool useMapMask;
            varying float v_discard;
            varying vec2 v_uv;
            varying float v_top;
            varying float v_p;
            uniform float time;

            mat4 rotateX(float angle) {
    float cosA = cos(angle);
    float sinA = sin(angle);
 return mat4(
        1.0, 0.0, 0.0, 0.0,
        0.0, cosA, -sinA, 0.0,
        0.0, sinA, cosA, 0.0,
        0.0, 0.0, 0.0, 1.0
    );
}
mat4 rotateY(float angle) {
    return mat4(
        vec4(cos(angle), 0.0, sin(angle), 0.0),
        vec4(0.0, 1.0, 0.0, 0.0),
        vec4(-sin(angle), 0.0, cos(angle), 0.0),
        vec4(0.0, 0.0, 0.0, 1.0)
    );
}
mat3 extractRotation(mat4 mat) {
    return mat3(mat[0].xyz, mat[1].xyz, mat[2].xyz);
}
vec3 extractRotationAngles(mat4 modelViewMatrix) {
    mat3 rotationMatrix = extractRotation(modelViewMatrix);
    
    float pitch = atan(rotationMatrix[1].z, rotationMatrix[2].z);
    float yaw = atan(-rotationMatrix[0].z, sqrt(rotationMatrix[1].z * rotationMatrix[1].z + rotationMatrix[2].z * rotationMatrix[2].z));
    float roll = atan(rotationMatrix[0].y, rotationMatrix[0].x);

    return vec3(pitch, yaw, roll);
}

vec4 rotateObjectTowardsCamera(vec3 position, float cameraYaw) {
    // Extract the position from the original model matrix
    // Construct a rotation matrix using the camera's yaw angle
    mat4 rotationMatrix = mat4(
        vec4(cos(cameraYaw), 0.0, sin(cameraYaw), 0.0),
        vec4(0.0, 1.0, 0.0, 0.0),
        vec4(-sin(cameraYaw), 0.0, cos(cameraYaw), 0.0),
        vec4(0.0, 0.0, 0.0, 1.0)
    );

    // Apply the rotation to the object's position
    vec4 rotatedPosition = rotationMatrix * vec4(position, 1.0);
    return rotatedPosition;
}

            void main() {
                vec3 pos = position;
                float m =  step(${(h / 4).toFixed(4)}, pos.y);
                float p = texture2D(perlin, fract(per + vec2(time * 0.15, time * 0.05))).r;
                float mask = texture2D(mapMask, vec2(per.x, 1. - per.y)).r;
                if (useMapMask && mask > 0.5) {
                  v_discard = 1.;
                }
                pos.y -= p * m * 0.075;
                pos.x -= p * m * 0.075;
                pos.z -= p * m * 0.033;
                v_uv = uv;
                v_top = m;
                v_p = p;
                // vec3 angles = extractRotationAngles(modelViewMatrix);
                //vec4 modelPos =  modelViewMatrix * rotateX(clamp(cameraPosition.y * 0.5, 1., 4.) * m * ${ses.toFixed(
                  3
                )}) * vec4(pos, 1.0);
                vec4 modelPos = modelViewMatrix * vec4(pos, 1.);
                gl_Position = projectionMatrix * modelPos;
  
            }
        `;

const fragmentShader = `
            uniform sampler2D colorMap;
            uniform sampler2D aoMap;
            varying vec2 v_uv;
            varying float v_top;
            varying float v_p;
            varying float v_discard;

            void main() {
                if (v_discard > 0.5) {
                  discard;
                }
                vec4 color = texture2D(colorMap, v_uv);
                // color = vec4(1., 1., 1., 1.);
                if (color.a <= 0.2) {
                  discard;
                }
                color.r *= ${r.toFixed(2)};
                color.g *= ${g.toFixed(2)};
                color.b *= ${b.toFixed(2)};
                vec3 ao = texture2D(aoMap, v_uv).rgb;
                // vec3 finalColor = color.rgb * (1.0 - ao);

                gl_FragColor = vec4(color.rgb * clamp(v_top, 0.2, 1.0) * mix(0.7, 1.0, v_p), 1.0);
            }
        `;

interface Props {
  fill?: boolean;
  useMapMask?: boolean;
  position?: [number, number, number];
}

export const Grass: FC<Props> = ({ fill, useMapMask, position }) => {
  const [texture, perlin, ao, mapMask] = useTexture([
    "grass.png",
    "perlin.png",
    "grass_ao.png",
    "map-mask.png",
  ]);
  const geometry = useMemo(() => {
    const g = new BufferGeometry();
    const points = [];
    const uv = [];
    const per = [];
    for (let i = 0; i < w; i++) {
      for (let j = 0; j < l; j++) {
        if (!fill) {
          // house cutout
          if (
            i / w > 107 / 300 &&
            i / w < 198 / 300 &&
            j / l > 95 / 300 &&
            j / l < 200.5 / 300
          )
            continue;
          // driveway cutout
          if (
            i / w > 161.45 / 300 &&
            i / w < 198 / 300 &&
            j / l > 184 / 300 &&
            j / l < 383 / 300
          ) {
            continue;
          }
        }
        const randpos = d * (Math.random() - 0.5);
        points.push(
          (i + randpos) * gx,
          h,
          0.0 + (j + e * d * (Math.random() - 0.5)) * gy, // x, y, z

          (i - 1 + randpos - d) * gx,
          // (i + randpos + d) * gx,
          0.0,
          0.0 + (j + e * d * (Math.random() - 0.5)) * gy,

          (i + randpos + d) * gx,
          0.0,
          0.0 + (j + e * d * (Math.random() - 0.5)) * gy
        );
        uv.push(
          0.5,
          1.0, // u, v

          0.0,
          0.0,

          1.0,
          0.0
        );
        per.push(i / w, j / l, i / w, j / l, i / w, j / l);
      }
    }

    g.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(points), 3)
    );
    g.setAttribute("uv", new BufferAttribute(new Float32Array(uv), 2));
    g.setAttribute("per", new BufferAttribute(new Float32Array(per), 2));
    g.computeVertexNormals();
    return g;
  }, []);
  const ref = useRef<Mesh>(null);
  const material = useMemo(() => {
    const m = new ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        colorMap: { value: texture },
        aoMap: { value: ao },
        perlin: { value: perlin },
        mapMask: { value: mapMask },
        useMapMask: { value: useMapMask },
        time: { value: 0 },
      },
      transparent: true,
      side: DoubleSide,
      //   depthWrite: false,
    });
    m.uniforms.colorMap.value = texture;
    m.uniformsNeedUpdate = true;

    return m;
  }, []);
  const timeRef = useRef(0);
  useFrame((three, delta) => {
    timeRef.current += delta;
    material.uniforms.time.value = timeRef.current;
    material.uniformsNeedUpdate = true;
  });
  return (
    <mesh
      ref={ref}
      args={[geometry, material]}
      position={position}
      scale={[4, 4, 4]}
    ></mesh>
  );
};
