/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { HoverMesh } from "./hover-mesh";
import { HoverGroup } from "./hover-group";

type GLTFResult = GLTF & {
  nodes: {
    INTER_ER___MEBEL__DLA_VODOSTOKOV: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI: THREE.Mesh;
    OBJ__COKAL: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002: THREE.Mesh;
    PLITA_PEREKR_TIA_DLA_STEN__OSNOVA: THREE.Mesh;
    OBJ__FASAD: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA002: THREE.Mesh;
  };
  materials: {
    ["Material.012"]: THREE.MeshStandardMaterial;
    ["Material.011"]: THREE.MeshStandardMaterial;
  };
};

export function House1(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/houses/House1.glb"
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <HoverGroup name="Scene">
        <mesh
          name="INTER_ER___MEBEL__DLA_VODOSTOKOV"
          castShadow
          receiveShadow
          geometry={nodes.INTER_ER___MEBEL__DLA_VODOSTOKOV.geometry}
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART.geometry
          }
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE.geometry}
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002
              .geometry
          }
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <HoverMesh
          name="5"
          castShadow
          receiveShadow
          geometry={nodes.OBJ__COKAL.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.011"]}
          position={[-4.947, 0.297, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001
              .geometry
          }
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002.geometry
          }
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="PLITA_PEREKR_TIA_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.PLITA_PEREKR_TIA_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <HoverMesh
          name="3"
          castShadow
          receiveShadow
          geometry={nodes.OBJ__FASAD.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA002.geometry
          }
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
      </HoverGroup>
    </group>
  );
}

useGLTF.preload("./meshes/houses/House1.glb");
