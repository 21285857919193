import { useFrame, useThree } from "@react-three/fiber";
import { useEventListener } from "app/event-listener";
import { PI, clamp, rad } from "app/math";
import { useDispatch, useSelector } from "app/store";
import {
  DbEntry,
  setDefaultView,
  selectModel,
  setScreen,
  setTier,
  setVariant,
} from "features/ui/slice";
import { FC, useEffect, useRef, useState } from "react";
import { Vector3 } from "three";

const ratio = 0.8;
interface Props {
  upAndDown?: boolean;
  target?: [number, number, number];
  height?: number;
  animate?: boolean;
}

export const destinations: {
  [key: string]: { radius: number; angle: number; model?: DbEntry };
} = {
  // house 1 roof
  "1 1 1": {
    radius: 3.000000000000001,
    angle: -0.9843656981247735,
    model: "крыша",
  },
  // house 1 walls
  "3 3 1": {
    radius: 2.6941813576212756,
    angle: 0.002246063644102689,
    model: "стены",
  },
  // house 1 interfloor
  "2 2 1": {
    angle: -1.9653569599106417,
    radius: 1.8,
    model: "межэтажное",
  },
  // house 1 foundation
  "6 6 1": {
    radius: 1.8,
    angle: -4.040789282602616,
    model: "фундамент",
  },
  // house 2 roof
  "1 1 2": {
    radius: 3.400000000000001,
    angle: -2.104867077905161,
    model: "крыша",
  },
  // house 2 walls
  "3 3 2": {
    radius: 2.9000000000000004,
    angle: -3.160907484359741,
    model: "стены",
  },
  // house 2 interfloor
  "2 2 2": {
    radius: 2.4038998169622765,
    angle: 0.004629861077885306,
    model: "межэтажное",
  },
  // house 2 foundation
  "6 6 2": {
    radius: 1.8,
    angle: -1.1354065355345402,
    model: "фундамент",
  },
  // house 1 base
  "5 5 1": {
    radius: 2.8,
    angle: -1,
    model: "цоколь",
  },
  // house 2 base
  "5 5 2": {
    radius: 2.8,
    angle: -2.14,
    model: "цоколь",
  },
  // default model view
  default: {
    radius: 2.4,
    angle: -PI / 2 - PI / 3,
  },
  // house 1 default
  "1 default": {
    radius: 3.1899999999999986,
    angle: -1.1780235837034185,
  },
  // house 2 default
  "2 default": {
    radius: 3.309999382184656,
    angle: -1.972446299574046,
  },
};

export const CameraControls: FC<Props> = ({
  upAndDown,
  target: _target,
  height = -0.7,
  animate,
}) => {
  const three = useThree();
  const pointerDown = useRef<boolean>(false);
  const target = useRef(new Vector3(...(_target || [0, -0.9, 0])));
  const angle = useRef<number>(-PI / 2 + PI / 4);
  const radius = useRef<number>(2.4);

  // handle canvas clicks
  const dispatch = useDispatch();
  const selection = useSelector(s => s.ui.selection);
  const hc = useSelector(s => s.ui.hoverCode);
  const chc = useSelector(s => s.ui.clickedHoverCode);
  const house = useSelector(s => s.ui.house);
  const screen = useSelector(s => s.ui.screen);
  const [destination, setDestination] = useState<string>("");

  const onClick = () => {
    const model = destinations[destination]?.model;
    if (model) {
      dispatch(setScreen("house-detail"));
      if (selection.options[model] !== undefined) {
        dispatch(setVariant(selection.options[model][0]));
        dispatch(setTier(selection.options[model][1]));
      } else {
        dispatch(setVariant(0));
        dispatch(setTier(0));
      }
      dispatch(selectModel(model));
    }
  };

  // set default camera position on button click
  const defaultView = useSelector(s => s.ui.defaultView);
  useEffect(() => {
    if (defaultView) {
      if (screen === "house") {
        setDestination(`${house} default`);
      } else setDestination("default");
    }
  }, [defaultView]);

  useEventListener("click", e => {
    if (
      (e.target as HTMLElement)?.tagName === "CANVAS" &&
      chc !== -1 &&
      animate
    ) {
      // onClick();
      console.log("click", hc, chc, house);
      const key = `${hc} ${chc} ${house}`;
      if (destinations[key]) {
        setDestination(key);
      }
    }
  });

  useEventListener("wheel", (e: WheelEvent) => {
    if (
      e.target &&
      e.target instanceof HTMLElement &&
      e.target.tagName !== "CANVAS"
    )
      return;
    // temporarily(!) disable camera controls when the user is in the configurator
    // if (screen === "configurator") return;
    const delta = e.deltaY;
    // disable animation when the user starts to drag manually
    setDestination("");
    console.log("camera", radius.current, angle.current);
    radius.current = clamp(radius.current + delta * 0.001, 1.8, 4);
  });
  useEventListener("pointerdown", () => {
    pointerDown.current = true;
  });
  useEventListener("pointermove", e => {
    // temporarily(!) disable camera controls when the user is in the configurator
    // if (screen === "configurator") return;
    if (pointerDown.current) {
      console.log("camera", radius.current, angle.current);
      if (defaultView) {
        dispatch(setDefaultView(false));
      }
      if (destination) {
        // disable animation when the user starts to drag manually
        setDestination("");
      }
      if (upAndDown) {
        const y = e.movementY || 0;
        target.current.y = clamp(target.current.y + y * 0.005, -1.8, 0.4);
      }
      const x = e.movementX;

      angle.current = (angle.current + rad(x * 0.3)) % (PI * 2);
    }
  });
  useEventListener("pointerup", () => {
    pointerDown.current = false;
  });
  useFrame((three, delta) => {
    if (destinations[destination]) {
      const { radius: r, angle: a } = destinations[destination];
      const deltaR = r - radius.current;
      const deltaA = a - angle.current;
      // console.log(deltaR, deltaA);
      radius.current += deltaR * 0.1; //delta * 3;
      angle.current += deltaA * 0.1; //delta * 3;
      if (Math.abs(deltaR) < 0.01 && Math.abs(deltaA) < 0.01) {
        // setDestination("");
        setTimeout(() => onClick(), 500);
      }
    }

    three.camera.position.y = height;
    three.camera.position.x = radius.current * Math.cos(angle.current) * ratio;
    three.camera.position.z = radius.current * Math.sin(angle.current);
    three.camera.lookAt(target.current);

    // use these logs to find desired camera positions
    // console.log({ radius: radius.current, angle: angle.current });
  });
  return null;
};
